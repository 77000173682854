<template lang="pug">
	transition(name="slide-fade")
		.home
			loading(:active.sync="busy", :is-full-page="true")
			.widget
				.widget-header
					h1.title Subsources
				.widget-body
					form.form(@submit.prevent="loadData()")
						.form-group(style="max-width: 400px")
							label Subsources
								small  (line separated)
							textarea.form-control(rows="6", v-model="filters.subsources")
						.form-group
							b-checkbox(v-model="filters.search_unmasked") Search Unmasked
						.form.form-inline.ov-filters
							.form-row-main
								.form-group
									label Dates
									date-range-picker(:localeData="datePickerOptions.locale", :ranges="datePickerOptions.ranges",
										v-model="filters.dateRange", :time-picker="true", :time-picker24-hour="true", :time-picker-increment="1")
								.form-group
									label &nbsp;
									//button.btn.btn-primary(type="submit") Submit
									b-button-group
										b-dropdown(split-button-type="submit", variant="primary", right, split, text="Submit")
											b-dropdown-item(@click="loadData(true)")
												i.la.la-download
												| Export
							.form-row-end
								.form-group
									label &nbsp;
									button.btn.btn-secondary(type="button", @click="unblockSelected()") Unblock Selected
								.form-group
									label &nbsp;
									button.btn.btn-secondary(type="button", @click="blockSelected()") Block Selected

					p.clearfix &nbsp;
					p.text-center Showing {{ records.length }} records
					.ov-table-wrapper
						table.table.table-bordered
							thead
								tr
									th.text-center(style="width: 40px;")
										input(type="checkbox", :checked="allChecked", @click="toggleAll()")
										//a(href="javascript:void(0)", @click="toggleAll()")
											i.la(:class="{'la-square-o':!allChecked, 'la-check-square-o':allChecked}")
									th ID
									th Subsource (adv.)
									th Subsource (pub.)
									th Publisher
									th Placement
									th Offer
									th Status
									th Rules
							tbody
								tr(v-if="!records.length")
									td(colspan="9") No matching records were found
								tr(v-for="r in records")
									td.text-center
										input(type="checkbox", v-model="r._selected")
									td {{ r.subsource.id }}
									td {{ r.keyword }}
									td {{ r.subsource.sub_id }}
									td
										entity(:id="r.subsource.publisher_id", :name="r.subsource.publisher_name", type="publisher")
									td
										entity(:id="r.subsource.placement_id", :name="r.subsource.placement_name", type="placement")
									td
										entity(:id="r.subsource.offer_id", :name="r.subsource.offer_name", type="offer")
									td
										.badge.badge-success(v-if="r.subsource.status==='open'") {{ r.subsource.status }}
										.badge.badge-warning(v-if="r.subsource.status==='custom'") {{ r.subsource.status }}
										.badge.badge-danger(v-if="r.subsource.status==='blocked'") {{ r.subsource.status }}
									td.actions
										button.btn.btn-action-default(@click="showRulesModal(r)", v-b-tooltip.hover.bottom, title="Rules")
											i.la.la-search


</template>
<style lang="scss" scoped>
.badge {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import SubsourceRulesModal from './modals/SubsourceRulesModal';

export default {
  name: 'Subsources',
  components: {
    // Graph
  },
  data() {
    return {
      busy: false,
      allChecked: false,
      records: [],
      filters: {
        timezone: 0,
        search_unmasked: false,
        subsources: '20122_2736267',
        dateRange: {
          startDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          endDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        },
      },
      datePickerOptions: {
        timePicker: true,
        timePicker24Hour: true,
        locale: {
          format: 'DD/MM/YYYY HH:mm',
        },
        alwaysShowCalendars: true,
        autoApply: true,
        ranges: {},
      },
    };
  },
  methods: {
    async _updateRecords(idList, status) {
      let params = {
        subsources: idList,
        status: status,
      };

      try {
        await this.$ovReq.post('subsource/updateSourcesStatus', params);
      } catch (e) {
        console.error(e);
      }
    },
    async blockSelected() {
      let idList = [];
      let indexHash = {};
      this.records.forEach((r, i) => {
        indexHash[r.subsource.id] = i;
        if (r._selected) {
          idList.push(r.subsource.id);
        }
      });
      let offset = 0;
      let limit = 50;
      while (offset < idList.length) {
        this.busy = true;
        try {
          let slice = idList.slice(offset, offset + limit);
          await this._updateRecords(slice, 'block');
          slice.forEach((id) => {
            this.records[indexHash[id]].subsource.status = 'blocked';
          });
        } catch (e) {
          console.error(e);
        }
        offset += limit;
        this.busy = false;
      }
    },
    async unblockSelected() {
      let idList = [];
      let indexHash = {};
      this.records.forEach((r, i) => {
        indexHash[r.subsource.id] = i;
        if (r._selected) {
          idList.push(r.subsource.id);
        }
      });
      let offset = 0;
      let limit = 50;
      while (offset < idList.length) {
        this.busy = true;
        try {
          let slice = idList.slice(offset, offset + limit);
          await this._updateRecords(slice, 'unblock');
          slice.forEach((id) => {
            this.records[indexHash[id]].subsource.status = 'open';
          });
        } catch (e) {
          console.error(e);
        }
        offset += limit;
        this.busy = false;
      }
    },

    toggleAll() {
      this.allChecked = !this.allChecked;
      this.records.forEach((r) => {
        r._selected = this.allChecked;
      });
    },
    updateRanges() {
      let tz = this.filters.timezone;
      let localOffset = new Date().getTimezoneOffset() / 60;
      let tzGap = -localOffset - tz;

      this.datePickerOptions.ranges = {
        'This Hour': [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Hour': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Today: [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Yesterday: [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 7 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(6, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 30 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(29, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'This Month': [
          moment(
            moment().subtract(tzGap, 'hours').startOf('month').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').endOf('month').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Month': [
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .startOf('month')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .endOf('month')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
      };
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let subsources = this.filters.subsources;
      let sourcesList = subsources.split('\n');
      let records = [];
      this.busy = true;
      if (sourcesList.length > 0) {
        if (doExport) {
          let params = {
            export: 1,
            search_unmasked: this.filters.search_unmasked,
            subsources: sourcesList.join('\n'),
            date_start: moment(this.filters.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
            date_end: moment(this.filters.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
          };
          try {
            let resp = await this.$ovReq.post('subsource/search', params);
            window.open(resp.path);
          } catch (e) {
            console.error(e);
          }
        } else {
          let offset = 0;
          let limit = 50;
          while (offset < sourcesList.length) {
            let slice = sourcesList.slice(offset, offset + limit);

            let params = {
              search_unmasked: this.filters.search_unmasked,
              subsources: slice.join('\n'),
              date_start: moment(this.filters.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
              date_end: moment(this.filters.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
            };
            try {
              let resp = await this.$ovReq.post('subsource/search', params);
              resp.records.forEach((r) => {
                records.push(r);
              });
            } catch (e) {
              console.error(e);
            }

            offset += limit;
          }

          this.records = records;
          this.records.forEach((r) => {
            Vue.set(r, '_selected', false);
          });
        }
      }

      this.busy = false;
    },

    async showRulesModal(r) {
      this.$modal.show(
        SubsourceRulesModal,
        {
          id: r.subsource.id,
          // rules: r.click_routes,
          // subsource: r.subsource,
          // publisher: r.publisher,
          // placement: null,
        },
        {
          height: '90%',
          width: 800,
        },
      );
    },
  },
  mounted() {
    this.updateRanges();
    let intv = setInterval(() => {
      this.updateRanges();
    }, 60 * 1000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(intv);
    });
  },
};
</script>
